<template>
  <!-- <Header /> -->
  <slot />
  <!-- <Footer v-if="meta.isShowFooter" /> -->
</template>
<script>
import { computed } from "vue";
import { useRoute } from "vue-router";

// import Header from "@/components/Header.vue";
// import Footer from "@/components/Footer.vue";
export default {
  // components: { Header, Footer },
  setup() {
    const route = useRoute();

    return {
      meta: computed(() => route.meta),
    };
  },
};
</script>
